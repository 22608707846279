<template>
	<div class="item">
		<Dropdown trigger="click" :visible="visible" placement='bottom-start' @on-visible-change='visibleChange'>
			<Button size='large' type="default">{{`${tableName}(${selectedIndexNum}/${orighinIndexNum})`}}
				<Icon type="md-arrow-dropdown" />
			</Button>
			<div slot="list">
				<div class="indexBox">
					<div class="bigTitle">
						{{tableName}}
					</div>
					<div class="functionBox">
						<Input search @on-change='searchIndex' clearable @on-clear='clearSearch' v-model="searchWord"
							suffix="ios-search" placeholder="搜索指标" style="width: 340px" />
						<div class="optionsBox">
							<Button @click="setDefault" type="text">默认</Button>
							<Button @click="setAll" type="text">全选</Button>
							<Button @click="setInvert" type="text">反选</Button>
							<Button @click="setNone" type="text">清空</Button>
						</div>
					</div>
					<div class="no-data" v-if="selectIndexListLength == 0">
						暂无搜索结果
					</div>
					<div v-else>
						<div v-for="(indexGroup,index) in selectIndexList" :key="index" class="checkGroup">
							<div v-if="indexGroup.list.length >0">
								<div v-if="indexGroup.title" class="title">
									{{indexGroup.title}}
								</div>
								<CheckboxGroup v-model="selectedIndexList" :key="CheckboxGroupKey">
									<Checkbox class='checkIndex' v-for="(indexs,_index) in indexGroup.list"
										:key='_index' :label="indexs.columnName">
										<Tooltip :disabled='indexs.columnDesc.length <13' theme='light'
											placement='bottom-start' :max-width='300' transfer
											:content="indexs.columnDesc">
											<div class="columnDescClass">
												{{indexs.columnDesc}}
											</div>
										</Tooltip>
									</Checkbox>
								</CheckboxGroup>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Dropdown>
	</div>
</template>

<script>
	export default {
		name: 'IndetPanelItem',
		props: ['indexList', 'tableName'],
		data() {
			return {
				searchWord: '',
				orighinIndexList: [],
				selectIndexList: [],
				visible: false,
				selectedIndexList: [],
				selectIndexListLength: 0,
				CheckboxGroupKey:0,
			}
		},
		watch: {
			List: {
				//初始化数据
				handler(val) {
					this.orighinIndexList = JSON.parse(JSON.stringify(val))
					this.selectIndexList = JSON.parse(JSON.stringify(val))
					this.$nextTick(() => {
						this.setDefault()
					})

				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			//计算获取的列表数据
			List() {
				return JSON.parse(JSON.stringify(this.indexList))
			},
			//计算所有的index数
			orighinIndexNum() {
				let num = 0
				this.orighinIndexList.forEach(list => {
					num += list.list.length
				})
				return num
			},
			//计算选择的index数
			selectedIndexNum() {
				let num = 0
				num = this.selectedIndexList.length
				return num
			},
		},
		methods: {
			handleOpen() {
				this.visible = true;
			},
			handleClose() {
				this.visible = false;
			},
			//存储列表数据
			selectCheckbox(arr) {
				let arrHaslabel = []
				arr.forEach(item => {
					this.selectIndexList.forEach(indexGroup => {
						indexGroup.list.forEach(index => {
							if (index.columnName == item) {
								arrHaslabel.push(index)
							}
						})
					})
				})
				let params = {
					tableName: this.tableName,
					list: arr
				}
				let paramsHaslabel = {
					tableName: this.tableName,
					list: arrHaslabel
				}
				this.$store.commit('reHouseEnterprise/changeIndex', params);
				this.$store.commit('reHouseEnterprise/changeIndexHaslabel', paramsHaslabel);
			},
			//下拉框隐藏的时候存储选择的列表数据
			visibleChange(e) {
				if (!e) {
					this.selectCheckbox(this.selectedIndexList)
				}
			},
			getSearchIndexLength(arr) {
				let length = 0
				arr.forEach(item => {
					length += item.list.length
				})
				return length
			},
			//搜索
			searchIndex(e) {
				let arr = JSON.parse(JSON.stringify(this.orighinIndexList))
				++ this.CheckboxGroupKey
				if (this.searchWord != '') {
					//筛选出搜索的指标
					arr.forEach(group => {
						if (group.list.length != 0) {
							group.list = group.list.filter(item => {
								if (item.columnDesc.indexOf(this.searchWord) > -1) {
									return item
								}
							})
						}
					})
				}
				this.selectIndexList = arr
				this.selectIndexListLength = this.getSearchIndexLength(arr)
			},
			//清空塞选词
			clearSearch() {
				let arr = JSON.parse(JSON.stringify(this.orighinIndexList))
				this.selectIndexList = arr
				this.selectIndexListLength = this.getSearchIndexLength(arr)
			},
			//默认
			setDefault() {
				let arr = JSON.parse(JSON.stringify(this.orighinIndexList))
				this.selectedIndexList = []
				arr.forEach(group => {
					if (group.list.length != 0) {
						group.list.forEach(indexClass => {
							if (indexClass.defaultType == 1) {
								this.selectedIndexList.push(indexClass.columnName)
							}
						})
					}
				})
				this.selectCheckbox(this.selectedIndexList)
				this.selectIndexListLength = this.getSearchIndexLength(this.selectIndexList)
			},
			//全选
			setAll() {
				let arr = JSON.parse(JSON.stringify(this.orighinIndexList))
				this.selectedIndexList = []
				arr.forEach(group => {
					if (group.list.length != 0) {
						group.list.forEach(indexClass => {
							this.selectedIndexList.push(indexClass.columnName)
						})
					}
				})
				this.selectCheckbox(this.selectedIndexList)
			},
			//清空
			setNone() {
				this.selectedIndexList = []
				this.selectCheckbox(this.selectedIndexList)
			},
			//反选
			setInvert() {
				let arr = JSON.parse(JSON.stringify(this.orighinIndexList))
				let selectedArr = JSON.parse(JSON.stringify(this.selectedIndexList))
				this.selectedIndexList = []
				let AllArr = []
				arr.forEach(group => {
					if (group.list.length != 0) {
						group.list.forEach(indexClass => {
							if (!selectedArr.includes(indexClass.columnName)) {
								this.selectedIndexList.push(indexClass.columnName)
							}
						})
					}
				})
				this.selectCheckbox(this.selectedIndexList)
			}
		}

	}
</script>

<style scoped lang="scss">
	.item {
		display: inline-block;
		margin-right: 5px;
	}

	/deep/.indexBox {
		width: 800px;
		height: 500px;
		padding: 10px 20px;
		overflow: auto;

		.bigTitle {
			font-size: 18px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			text-align: left;
			color: #333333;
			line-height: 36px;

		}

		.functionBox {
			padding-top: 30px;
			padding-bottom: 25px;
			border-bottom: 1px solid rgba($color: #999999, $alpha: 0.2);

			.optionsBox {
				display: inline-block;
				margin-left: 150px;
			}
		}

		.checkGroup {
			margin-top: 30px;

			.title {
				position: relative;
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				font-weight: 400;
				text-align: left;
				color: #333333;

				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 24px;
					top: 4px;
					left: -10px;
					background-color: #00B6FF;
				}
			}

			.checkIndex {
				display: inline-block;
				width: 30%;
				margin-top: 10px;

				.columnDescClass {
					height: 40px;
					line-height: 40px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	::v-deep.ivu-select-dropdown {
		max-height: 500px !important;
	}

	/deep/.ivu-tooltip {
		width: 90%;
		height: 40px;
		overflow: hidden;
		position: relative;
		top: 16px;
		left: 5px;
	}

	.no-data {
		width: 100%;
		font-size: 16px;
		color: #ccc;
		text-align: center;
		padding-top: 30px;
	}
	::v-deep .ivu-select-dropdown{
		top: 40px !important;
	}
</style>
