<template>
	<div class="tablePanel">
		<Table :columns="columns" :data="data" border :span-method="handleSpan" :height='850'>
			<template slot-scope="{ row }" slot="指标分类">
				<div class="index">{{row.指标分类}}</div>
			</template>
			<template slot-scope="{ row }" slot="指标">
				<div class="index">{{row.指标}}</div>
			</template>
		</Table>
	</div>
</template>

<script>
	export default {
		name: 'tablePanel',
		data() {
			return {
				columns: [],
				data: [],
			}
		},
		computed: {
			financialData() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.financialData))
			},
			spinShow: {
				get() {
					return this.$store.state.reHouseEnterprise.spinShow;
				},
				set(newValue) {
					this.$store.state.reHouseEnterprise.spinShow = newValue;
				}
			},
		},
		watch: {
			financialData(val, old) {
				this.setColumns(val.heads)
				this.setData(val.records)
			}
		},
		methods: {
			handleSpan({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 0) {
					//计算合并的行数列数
					let x = row.one == 0 ? 0 : row.one
					let y = row.one == 0 ? 0 : 1
					return [x, y]
				}
				if (columnIndex == 1) {
					//计算合并的行数列数
					let x = row.two == 0 ? 0 : row.two
					let y = row.two == 0 ? 0 : 1
					return [x, y]
				}
			},
			setColumns(columns) {
				this.columns = []
				columns.forEach((item, index) => {
					let obj = {}
					obj.title = item
					obj.key = item
					obj.align = index == 2 ? 'left' : 'center'
					obj.slot = index == 2 || index == 1 ? item : null
					obj.minWidth = 200
					if (index == 0 || index == 1 || index == 2) {
						obj.fixed = 'left'
					}
					this.columns.push(obj)
				})
			},
			setData(data) {
				this.data = this.assembleData(this.assembleData(data, '', '财务报表', 'one'), '财务报表', '指标分类', 'two')
				this.$nextTick(() => {
					this.spinShow = false
				})
			},
			//函数处理
			//处理重复的分类
			assembleData(data, oneKey, spanKey, mergeCol) {
				//data为原始数据
				//spanKey为需要合并的key
				//mergeCol为合并的列
				let Data = []
				data.forEach(e => {
					let obj = {}
					for (let key in e) {
						obj[key] = e[key]
					}
					Data.push(obj)
				})
				let keys = []
				//筛选出不重复的 key值,将其放到 keys数组中
				Data.forEach(e => {
					if (!keys.includes(`${e[oneKey]}-${e[spanKey]}`)) {
						keys.push(`${e[oneKey]}-${e[spanKey]}`)
					}
				})
				let keyNums = []
				//将keys数组中的 key值设置默认合并0个单元格,放到 keyNums中
				keys.forEach(e => {
					keyNums.push({
						key: e,
						num: 0
					})
				})
				//计算每种 key值所在行需要合并的单元格数
				Data.forEach(e => {
					keyNums.forEach(n => {
						if (`${e[oneKey]}-${e[spanKey]}` == n.key) {
							n.num++
						}
					})
				})
				// //将计算后的合并单元格数整合到 data中
				Data.forEach(e => {
					keyNums.forEach(n => {
						if (`${e[oneKey]}-${e[spanKey]}` == n.key) {
							if (keys.includes(`${e[oneKey]}-${e[spanKey]}`)) {
								e[mergeCol] = n.num
								//删除已经设置过的值(防止被合并的单元格进到这个 if 语句中)
								keys.splice(keys.indexOf(n.key), 1)
							} else {
								//被合并的单元格设置为 0
								e[mergeCol] = 0
							}
						}
					})
				})
				// //将整理后的数据交给表格渲染
				return Data
			}
		}
	}
</script>

<style scoped lang="scss">
	.tablePanel {
		width: 100%;
	}

	.index {
		white-space: pre-wrap;
	}
</style>
