<template>
	<div class="indexPanel">
		<Item v-for="(item,index) in indexList" :key="index" :indexList='item.titleLists' :tableName='item.name'></Item>
	</div>
</template>

<script>
	import {
		fin_index
	} from '@/api/reHouseEnterprise.js'
	import Item from './Item.vue'
	export default {
		name: 'indexPanel',
		props:['id'],
		data() {
			return {
				indexList: []
			}
		},
		components: {
			Item
		},
		watch: {
			selectedCompantId(val) {
				this.getAllIndex(val)
			}
		},
		computed: {
			selectedCompantId() {
				// return this.$store.state.reHouseEnterprise.selectedCompantId
				return this.id
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.getAllIndex(this.selectedCompantId)
			},
			async getAllIndex(id) {
				let params = {
					entId: id
				}
				this.indexList = await fin_index(params)
			},
		}
	}
</script>

<style scoped>

</style>
