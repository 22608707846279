<template>
	<div class="searchPanel">
		<Form ref="searchForm" :model="searchForm" :label-width="120">
			<FormItem prop="type">
				<div class="labelClass" slot='label'>
					报表类型
				</div>
				<Select multiple :max-tag-count='1' size='large' style="width: 180px;border-radius: 8px;"
					v-model="searchForm.reportType">
					<Option :key="_type.code" v-for="_type in typeList" :value="_type.name">{{_type.name}}</Option>
				</Select>
				<DatePicker size='large' :options="dimStartOptions" @on-change='selectStartTime' v-model="dimStart"
					:editable='false' type="year" placeholder="起始日期" style="width: 180px;margin-left: 15px;">
				</DatePicker>
				—
				<DatePicker size='large' :start-date='dimStart' :options="dimEndOptions" @on-change='selectEndTime'
					v-model="dimEnd" :editable='false' type="year" placeholder="结束日期" style="width: 180px;">
				</DatePicker>
				<Tooltip style='margin-left: 15px;' content="最多可查询3年数据">
					<span class="askInfo">?</span>
				</Tooltip>
			</FormItem>
			<FormItem prop="type">
				<div class="labelClass" slot='label'>
					选择指标
				</div>
				<IndexPanel :id='selectedCompantId'></IndexPanel>
			</FormItem>
			<FormItem>
				<Button class="searchBtnLog" data-id="1" style="width: 140px;" size='large' type="info" @click="search">查询</Button>
			</FormItem>
		</Form>
	</div>
</template>

<script>
import Vue from 'vue'
import { log } from '../../../../utils'
	import IndexPanel from '../../components/IndexPanel/index.vue'
	export default {
		name: 'searchPanel',
		components: {
			IndexPanel
		},
		props: ['id', 'defaultTimeDim'],
		data() {
			let _this = this
			return {
				dimStart: new Date(`${((new Date().getFullYear())-_this.defaultTimeDim) .toString()}-01-01 00:00:00`),
				dimEnd: new Date(`${((new Date().getFullYear())-1) .toString()}-01-01 00:00:00`),
				searchForm: {
					reportType: ['年报'],
					dimStart: ((new Date().getFullYear()) - _this.defaultTimeDim).toString(),
					dimEnd: ((new Date().getFullYear()) - 1).toString(),
					indicators: [],
				},
				typeList: [],
				//结束日期禁用规则
				dimEndOptions: {
					disabledDate(date) {
						if (_this.dimStart !== '') {
							return date && date.valueOf() < _this.dimStart || date.valueOf() > new Date(
									`${new Date(_this.dimStart).getFullYear()+2}-01-01 00:00:00`) || date.valueOf() >
								new Date()
							// return false
						} else {
							return date && date.valueOf() > new Date()
						}

					}
				},
				//开始日期启用规则
				dimStartOptions: {
					disabledDate(date) {
						// return date && date.valueOf() > _this.dimEnd || date.valueOf() < new Date(
						// 	`${new Date(_this.dimEnd).getFullYear()-2}-01-01 00:00:00`)
						return date && date.valueOf() > new Date()
					}
				},
			}
		},
		watch: {
			//选择的企业为港股企业时，控制报表类型下拉框只能选择年报和中报
			componeyData(val) {
				//切换企业时，重置为年报
				this.searchForm.reportType = ['年报']
				if (val.ssqk && val.ssqk == '港交所') {
					this.typeList = [{
						name: '年报',
						code: 1
					}, {
						name: '中报',
						code: 3
					}]
				} else {
					this.typeList = [{
						name: '年报',
						code: 1
					}, {
						name: '三季报',
						code: 2
					}, {
						name: '中报',
						code: 3
					}, {
						name: '一季报',
						code: 4
					}, ]
				}
			}
		},
		computed: {
			selectedCompantId() {
				return this.id
			},
			componeyData() {
				return this.$store.state.reHouseEnterprise.componeyData
			},
		},
		mounted(){
			this.changeOption()
		},
		methods: {
			selectStartTime(e) {
				this.selectTime('dimStart', e)
				this.selectTime('dimEnd', '')
				this.dimEnd = ''
			},
			selectEndTime(e) {
				this.selectTime('dimEnd', e)
			},
			selectTime(key, value) {
				this.searchForm[key] = value
			},
			search() {
				this.searchForm.indicators = this.$store.state.reHouseEnterprise.selectedIndex
				// this.$store.commit('reHouseEnterprise/setSearchForm', this.searchForm)
				this.$emit('search', this.searchForm)
			},
			changeOption() {
				if (this.componeyData.ssqk && this.componeyData.ssqk == '港交所') {
					this.typeList.push({
						name: '年报',
						code: 1
					}, {
						name: '中报',
						code: 3
					})
				} else {
					this.typeList.push({
						name: '年报',
						code: 1
					}, {
						name: '三季报',
						code: 2
					}, {
						name: '中报',
						code: 3
					}, {
						name: '一季报',
						code: 4
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.searchPanel {
		// padding-top: 30px;
	}

	.labelClass {
		font-size: 16px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #747c8a;
	}

	.askInfo {
		width: 18px;
		height: 18px;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		display: inline-block;
		color: #808695;
		border: 1px solid #808695;
		border-radius: 50%;
		cursor: pointer;
	}
</style>
