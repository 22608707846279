<template>
	<div class="financial">
		<Row style='z-index: 10;' class='chuck'>
			<SearchPanel ref="SearchPanel" @search='search' :id='selectedCompantId' :defaultTimeDim='3'></SearchPanel>
		</Row>
		<Row v-show="settlementDate" class='chuck'>
			<Row class="titleBox">
				<div class="title">
					<span class="info">{{`财务分析（${searchForm.dimStart}年至${searchForm.dimEnd}年）`}}</span> <span
						class="time">{{`报告年结日:${settlementDate}`}}</span>
				</div>
				<div class="functionBox">
					<div v-if="btn==1" class="item currency">
						币种：人民币
					</div>
					<div v-if="btn==1" class="item unit">
						<Select size='large' style="width: 120px;border-radius: 8px;" v-model="unit">
							<Option :key="unit.code" v-for="unit in unitList" :value="unit.name">{{unit.name}}</Option>
						</Select>
					</div>
					<div v-if="btn==1" class="item download">
						<Button size='large' type="info" @click="exportData">下载表格</Button>
					</div>
					<div v-if="btn == 2" class="item chartFun">
						<span class="item info">左轴指标：</span>
						<span class="item selectFun">
							<Select size='large' style="width: 120px;border-radius: 8px;" v-model="index" label-in-value
								@on-change='searchView'>
								<Option :key="`${index.tableName}${index.columnName}`" v-for="index in indexList" :tag='index.tableName'
									:label='index.columnDesc' :value="index.columnName">
									<div slot>{{index.columnDesc}}</div>
								</Option>
							</Select>
						</span>
					</div>
					<div class="item changeForm">
						<div title="切换成表格" :class="['btn','table',btn == 1 ? 'active' : '']" @click="switchTable(1)">
						</div>
						<div title="切换成图表" :class="['btn','chart',btn == 2 ? 'active' : '']" @click="switchTable(2)">
						</div>
					</div>
				</div>
			</Row>
			<Row class='contentPanel'>
				<keep-alive>
					<component :is="componentTag"></component>
				</keep-alive>
			</Row>
		</Row>
		<Spin style='z-index: 99;' fix v-if="spinShow"></Spin>

	</div>
</template>

<script>
	import {
		fin_ent_list,
		fin_ent_view
	} from '@/api/reHouseEnterprise.js'
	import SearchPanel from './components/searchPanel.vue'
	import TablePanel from './components/tablePanel.vue'
	import ChartPanel from './components/chartPanel.vue'
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: 'financial',
		components: {
			SearchPanel,
			TablePanel,
			ChartPanel,
		},
		mixins: [dataReports],
		data() {
			return {
				componentTag: 'TablePanel',
				btn: '1',
				searchParams: {},
				unit: '亿元',
				unitList: [{
						name: '元',
						code: 1
					},
					{
						name: '万元',
						code: 3
					},
					{
						name: '亿元',
						code: 5
					},
				],
				indexList: [],
				//指标名
				index: '',
				//表名
				tag: '',
				searchForm: {},
				isInit: true,
			}
		},
		computed: {
			selectedCompantId() {
				return this.$store.state.reHouseEnterprise.selectedCompantId
			},
			// searchForm() {
			// 	return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.searchForm))
			// },
			settlementDate() {
				return this.$store.state.reHouseEnterprise.financialData.settlementDate
			},
			selectedIndexHaslabel() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.selectedIndexHaslabel))
			},
			canGetInitData() {
				if (this.selectedIndexHaslabel.length != 0 && this.isInit) {
					return true
				} else {
					return false
				}
			},
			spinShow: {
				get() {
					return this.$store.state.reHouseEnterprise.spinShow;
				},
				set(newValue) {
					this.$store.state.reHouseEnterprise.spinShow = newValue;
				}
			},
		},
		watch: {
			unit(val) {
				this.search()
			},
			selectedIndexHaslabel(val) {
				let arr = []
				val.forEach(item => {
					item.list.forEach(_class => {
						arr.push(_class)
					})
				})
				this.indexList = arr
				this.index = this.indexList[0] ? this.indexList[0].columnName : ''
				this.tag = this.indexList[0] ? this.indexList[0].tableName : ''
			},
			canGetInitData(val) {
				if (val) {
					this.$refs.SearchPanel.search()
				}
			}
		},
		//清空所选的指标
		beforeDestroy() {
			this.$store.commit('reHouseEnterprise/changeState', {
				prop: "selectedIndex",
				value: []
			})
			this.$store.commit('reHouseEnterprise/changeState', {
				prop: "selectedIndexHaslabel",
				value: []
			})
		},
		methods: {
			//查询表
			async search(res) {
				let params
				if (res) {
					params = JSON.parse(JSON.stringify(res))
					this.searchForm = params
				} else {
					params = this.searchForm
				}
				params.unit = this.unit
				params.entId = this.selectedCompantId
				//检测时间周期
				if (params.dimStart == '' || params.dimEnd == '') {
					this.$msg.error({
						text: '请选择时间周期'
					});
					return
				}
				//检测所选指标
				let length = 0
				params.indicators.forEach(item => {
					length += item.list.length
				})
				if (length == 0) {
					this.$msg.error({
						text: '请选择财务指标'
					});
					return
				}
				this.spinShow = true
				let data = await fin_ent_list(params)
				this.$store.commit('reHouseEnterprise/setFinancialData', data)
				this.switchTable(1)
				//查询后关闭是否init的控制
				this.isInit = false
			},
			//下载
			async exportData() {
				this.$btnSysLog(1,1)
				let params = this.searchForm
				let data = await fin_ent_list(params)
				const tableColumns = data.heads;
				let filterVal = [];
				let tHeader = [];
				tableColumns.map((item, index) => {
					tHeader.push(item);
					filterVal.push(item);
				});
				const resData = await formatJson(filterVal, data.records)
				downloadData(tHeader, resData, "财务报表");
			},
			//改变表格
			switchTable(type) {
				this.btn = type
				if (type == 1) {
					this.componentTag = 'TablePanel'
				} else if (type == 2) {
					this.componentTag = 'ChartPanel'
					this.searchView({
						value: this.index,
						tag: this.tag
					})
				}
			},
			//查询图
			async searchView(column) {
				let params = {
					dimEnd: this.searchForm.dimEnd,
					dimStart: this.searchForm.dimStart,
					entId: this.searchForm.entId,
					reportType: this.searchForm.reportType,
					unit: this.searchForm.unit,
					columnName: column.value,
					tableName: column.tag,
				}
				let data = await fin_ent_view(params)
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "chartData",
					value: data
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.financial {
		padding: 0px 20px;

		.chuck {
			width: 100%;
			position: relative;
			left: 50%;
			transform: translate(-50%);
			background: #ffffff;
			border-radius: 8px;
			box-shadow: -4px 0px 24px 0px rgba(51, 51, 51, 0.05);
			padding: 20px 50px;
			border: 1px solid rgba($color: #999999, $alpha: 0.1);
			margin-top: 30px;
			padding-top: 30px;

			.titleBox {
				width: 100%;
				display: flex;

			}

			.contentPanel {
				width: 100%;
				margin-top: 30px;
			}

			.noData {
				width: 100%;
				font-size: 24px;
				font-family: Source Han Sans CN, Source Han Sans CN-Medium;
				font-weight: 500;
				text-align: left;
				color: #333333;
				height: 48px;
				line-height: 48px;
				text-align: center;
				flex: 1;
			}

			.title {
				height: 48px;
				flex: 1;

				.info {
					font-size: 18px;
					font-family: Source Han Sans CN, Source Han Sans CN-Medium;
					font-weight: 500;
					text-align: left;
					color: #333333;
					height: 48px;
					line-height: 48px;
					display: inline-block;

				}

				.time {
					font-size: 14px;
					font-family: Source Han Sans CN, Source Han Sans CN-Normal;
					font-weight: Normal;
					text-align: left;
					color: #999999;
					height: 48px;
					line-height: 48px;
					display: inline-block;
				}
			}

			.functionBox {
				height: 48px;
				flex: 1;
				position: relative;

				>.item {
					position: absolute;
				}

				.currency {
					font-size: 16px;
					font-family: Source Han Sans CN, Source Han Sans CN-Regular;
					font-weight: 400;
					text-align: left;
					color: #333333;
					right: 355px;
					height: 40px;
					line-height: 40px;
				}

				.unit {
					width: 120px;
					right: 215px;
				}

				.download {
					right: 100px;
				}

				.changeForm {
					width: 80px;
					height: 40px;
					right: 0;

					.btn {
						width: 40px;
						height: 40px;
						display: inline-block;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100% 100%;
						cursor: pointer;

						&.table {
							background-image: url(../../../assets/image/finance/table.png);

							&.active {
								background-image: url(../../../assets/image/finance/table_active.png);
							}
						}

						&.chart {
							background-image: url(../../../assets/image/finance/chart.png);

							&.active {
								background-image: url(../../../assets/image/finance/chart_active.png);
							}
						}
					}
				}

				.chartFun {
					height: 40px;
					right: 100px;
					width: 200px;
					font-size: 0;

					.item {
						display: inline-block;
						vertical-align: middle;
					}

					.info {
						font-size: 16px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						text-align: left;
						color: #333333;
						line-height: 40px;
						height: 40px;
					}
				}
			}
		}
	}
</style>
