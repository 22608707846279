<template>
	<div class="chartPanel">
		<div id="charts" ref="charts">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'chartPanel',
		computed: {
			chartData() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.chartData))
			},
		},
		watch: {
			chartData(val) {
				this.setEChartOption(val)
			}
		},
		data() {
			return {
				echart: '',
				options: {
					color: ['#0485DC', '#719FF7', '#6FDCB2', '#F3AE7C', '#E97C65', '#7E97B3', '#7B7FBB', '#38B4D5',
						'#F5C736', '#E97C65'
					],
					legend: {},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						// formatter: '{a}{b}{c}{d}'	

					},
					dataset: [],
					// 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
					xAxis: {
						type: 'category'
					},
					// 声明一个 Y 轴，数值轴。
					yAxis: [{
							type: 'value',
							name: '亿元',
							min: 0,
							max: 1000,
							position: 'left',
							splitNumber: 10

						}, {
							type: 'value',
							name: '%',
							position: 'right',
							splitNumber: 10,
							splitLine: {
								show: false //去掉网格线
							}

						},

					],
					// 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
					series: [{
						type: 'bar',
						datasetIndex: 0,
						yAxisIndex: 0,
						barMaxWidth: this.type == 'line' ? null : 100, //柱图最大宽度,
					}, {
						type: 'line',
						datasetIndex: 1,
						yAxisIndex: 1,
					}, ]
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.echart = this.$echarts.init(this.$refs.charts)
				//监听window的resize，然后重置E chart的宽度
				window.addEventListener("resize", this.echart.resize)
				this.echart.setOption(this.options)
			},
			//修改E chart的数据
			setEChartOption(data) {
				let option = JSON.parse(JSON.stringify(this.options))
				let keys = Object.getOwnPropertyNames(data)
				for (let i = 0; i < keys.length; i++) {
					let key = keys[i]
					let Max = Math.max(...data[key].value)
					let Min = Math.min(...data[key].value)
					// let Middle = this.getMiddle(...data[key].value)
					option.yAxis[i].max = this.getMaxY(Max, i)
					option.yAxis[i].min = this.getMinY(Min, i)
					option.yAxis[i].name = data[key].unit ? data[key].unit : ''
					option.dataset.push(this.getDataSet(data[key]))
					option.series[i].name = data[key].head[1]
				}
				option.tooltip.formatter = function(params) {
					return params[0].name + "<br>" + params[0].marker + params[0].seriesName + ":" + params[0].data[
							1] +
						`${data.value.unit}` + "<br>" + params[1].marker +
						params[1].seriesName + ":" + params[1].data[1] + `${data.yoys.unit}`
				}
				this.echart.setOption(option)
			},
			//获取最大值
			getMaxY(value, type) {
				if (type == 0) {
					//柱状图
					if(value > 0){
						let e = Math.ceil(value).toString().length
						return (Math.ceil(value * 1.3 / (Math.pow(10, e - 2))) * (Math.pow(10, e - 2))).toFixed(2)
					}else{
						return 0
					}
					
				} else {
					if (value > 0) {
						return (Math.ceil(value * 1.3 * 10) / 10).toFixed(2)
					} else {
						return (Math.ceil(Math.abs(value) * 1.3 * 10) / 10).toFixed(2)
					}
				}
			},

			//获取最小值
			getMinY(value, type) {
				if (type == 0) {
					//柱状图
					if (value > 0) {
						return 0
					} else {
						return -(Math.ceil(Math.abs(value) * 1.3 * 10) / 10).toFixed(2)
					}
					// return 0
				} else {
					//折线图
					if (value > 0) {
						return (Math.floor(value / 1.3 * 10) / 10).toFixed(2)
					} else {
						return -(Math.ceil(Math.abs(value) * 1.3 * 10) / 10).toFixed(2)
					}
				}
			},
			//获取平均值
			getMiddle() {
				let sum = 0
				for (let i = 0; i < arguments.length; i++) {
					sum += arguments[i]
				}
				return sum / arguments.length

			},
			//设置数值
			getDataSet(data) {
				let obj = {}
				obj.dimensions = data.head
				obj.source = []
				for (let i = 0; i < data.value.length; i++) {
					let arr = []
					arr.push(data.date[i], data.value[i])
					obj.source.push(arr)
				}
				let Data = []
				Data.push(obj.dimensions)
				Data = Data.concat(obj.source)
				return {
					source: Data
				}
			}

		}
	}
</script>

<style scoped lang="scss">
	.chartPanel {
		width: 100%;

		#charts {
			width: 100%;
			height: 900px;
		}
	}
</style>
